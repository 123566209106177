import React from "react";
import PropTypes from "prop-types";

import get from "lodash/get";
import isEqual from "lodash/isEqual";

import SubHeading from "../SubHeading";
import Section from "../Section";
import EmailItem from "../EmailItem";
import sortEmail from "../../util/sortEmail";

import categories from "../../constants/emailCategories";

/**
 * EmailList -
 * Lists of emails under category subsections
 */
const EmailList = ({ emails, isDevEnv }) =>
    categories
        .filter(
            ([, groupKey]) => !isEqual(groupKey, "unlisted") || isDevEnv // Don't show 'unlisted' category in production
        )
        .map(([title, groupKey, highlight]) => {
            const groupedEmail = get(emails, groupKey, []).sort(sortEmail);
            const noContent = groupedEmail.length < 1;
            return (
                <Section key={title} id={groupKey} {...{ highlight, noContent }}>
                    <SubHeading>{title}</SubHeading>
                    <ul className="mt-3 mb-2 pl-4">
                        {groupedEmail.map((node) => (
                            <EmailItem email={node} key={node.id} />
                        ))}
                        {noContent && (
                            <div className="text-center grey-300 py-4">
                                {" "}
                                No emails found for this category.{" "}
                            </div>
                        )}
                    </ul>
                </Section>
            );
        });

EmailList.propTypes = {
    emails: PropTypes.object.isRequired,
    isDevEnv: PropTypes.bool
};

EmailList.defaultProps = {
    isDevEnv: false
};

export default EmailList;
