import React from "react";
import PropTypes from "prop-types";

import classnames from "classnames";

const Section = ({ children, id, highlight, noContent }) => {
    return (
        <section
            id={id}
            className={classnames("py-2 px-2 border-gray-200 rounded-sm mt-2 mb-12 bg-gray-50", {
                "border-2 bg-gray-100": highlight,
                "opacity-50": noContent
            })}
        >
            {children}
        </section>
    );
};

Section.propTypes = {
    children: PropTypes.node.isRequired,
    highlight: PropTypes.bool,
    noContent: PropTypes.bool,
    id: PropTypes.string.isRequired
};

Section.defaultProps = {
    highlight: false,
    noContent: false
};

export default Section;
