import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";

import initial from "lodash/initial";
import isEqual from "lodash/isEqual";
import mapValues from "lodash/mapValues";
import reject from "lodash/reject";

import { useGroupBy } from "../hooks/data";
import categories from "../constants/emailCategories";
import FilterBar, { CHECKBOX, defaultFilters } from "../components/FilterBar";
import SubDirBar from "../components/SubDirBar";
import PageHeading from "../components/PageHeading";
import Layout from "../components/Layout";
import EmailList from "../components/EmailList";
import dirPath from "../util/templateDirPath";

const isDevEnv = isEqual(process.env.NODE_ENV, "development");
const showUpcomingTemplates = process.env.GATSBY_SHOW_UPCOMING_TEMPLATES === "true";

const EmailIndexPage = ({ pageContext: { emailData, brandInfo, subBrandInfo = {} } }) => {
    const { key: brandKey, displayName, palette, subBrands = [] } = brandInfo;
    const { key: subBrandKey, displayName: subBrandDisplayName } = subBrandInfo;
    const isSubBrand = !!subBrandKey;
    const groupKeys = initial(categories).map(([, groupKey]) => groupKey); // 'initial' skips the finial category, which should be "misc"
    const groupedEmails = useGroupBy(emailData, groupKeys);

    const [filterSettings, setFilterSettings] = useState(defaultFilters);

    const crumbs = [
        { current: !isSubBrand, title: displayName, to: dirPath(brandKey) },
        ...(isSubBrand
            ? [{ current: true, title: subBrandDisplayName, to: dirPath(brandKey, subBrandKey) }]
            : [])
    ];

    const filteredEmails = useMemo(
        () =>
            mapValues(groupedEmails, (emails) =>
                reject(emails, ({ frontmatter: { upcoming, devOnly, deprecated } }) => {
                    const isSpecial = upcoming || devOnly || deprecated; // 'special' emails are only visible in dev env

                    if (upcoming && showUpcomingTemplates) return false;

                    return isSpecial
                        ? !isDevEnv ||
                              (devOnly && !filterSettings[CHECKBOX.DEV_ONLY]) ||
                              (upcoming &&
                                  !filterSettings[CHECKBOX.UPCOMING] &&
                                  !showUpcomingTemplates) ||
                              (deprecated && !filterSettings[CHECKBOX.DEPRECATED])
                        : !filterSettings[CHECKBOX.DEFAULT];
                })
            ),
        [groupedEmails, filterSettings]
    );

    return (
        <Layout crumbs={crumbs} brandColor={palette.secondary}>
            <PageHeading brandInfo={brandInfo}>
                <>
                    <span className="font-bold" style={{ color: palette.primary }}>{`${
                        subBrandDisplayName || displayName
                    } `}</span>
                    email template
                    {` ${isSubBrand ? "subsection" : "library"}`}.
                </>
            </PageHeading>

            <div className="container mx-auto">
                <div className="grid grid-cols-1 md:grid-cols-12 gap-4 md:gap-8">
                    <div className="md:col-start-2 md:col-span-10">
                        {isDevEnv && (
                            <FilterBar
                                {...{
                                    filterSettings,
                                    setFilterSettings,
                                    filteredEmails,
                                    groupedEmails
                                }}
                            />
                        )}

                        <SubDirBar
                            subDirs={subBrands}
                            fallback={brandInfo}
                            insideDir={isSubBrand && subBrandKey}
                        />
                        <EmailList emails={filteredEmails} isDevEnv={isDevEnv} />
                    </div>
                </div>
            </div>
        </Layout>
    );
};

EmailIndexPage.propTypes = {
    pageContext: PropTypes.shape({
        emailData: PropTypes.object.isRequired,
        brandInfo: PropTypes.shape({
            key: PropTypes.string.isRequired,
            displayName: PropTypes.string.isRequired,
            palette: PropTypes.object.isRequired,
            subBrands: PropTypes.array
        }).isRequired,
        subBrandInfo: PropTypes.shape({
            key: PropTypes.string,
            displayName: PropTypes.string
        })
    }).isRequired
};

export default EmailIndexPage;
