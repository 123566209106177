const emailCategories = [
    // Third item in array determines whether to give the section a highlight grey background
    ["🤖 Beta (Internal)", "beta", true],
    ["👪 Beta (Friends and Family)", "beta-f&f", true],
    ["👩‍💼 NOD & NOSIA", "nosia", true],
    ["🚀 On-boarding", "onboarding"],
    ["👥 Customer led", "customer-led"],
    ["🔧 Servicing", "servicing"],
    ["👵 Maturity", "maturity"],
    ["💸 Payments/arrears", "payments/arrears"],
    ["💰 Rewards", "rewards"],
    ["👮 Rules", "rules"],
    ["🦠 COVID-19", "covid19"],
    ["🏦 Direct Debit", "directdebit"],
    ["🌅 Early Settlement Quotes", "esq"],
    ["🌚 Hidden [Unlisted]", "unlisted"],
    ["🐙 Misc", "misc"]
];

export default emailCategories;
