export const useGroupBy = (data, groups) => {
    const grouped = {
        misc: []
    };
    // Extend the re
    groups.forEach((group) => {
        grouped[group] = [];
    });

    data.forEach((node) => {
        const category = node?.frontmatter?.category;
        if (category) {
            try {
                grouped[category?.toLowerCase()].push(node);
            } catch {
                // noop
            }
        } else {
            grouped.misc.push(node);
        }
    });

    return grouped;
};
