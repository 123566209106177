import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";

const RawFooter = ({ className }) => {
    return (
        <div className={`bg-gray-700 pb-5 py-3 ${className}`}>
            <h6 className="text-center text-lg text-gray-100 mt-5 font-light">
                Made with ❤️ at Chetwood Financial Ltd.
            </h6>
        </div>
    );
};

const PageFooter = styled(RawFooter)`
    ${(props) => (props.background ? `background-color: ${props.background};` : "")}
`;

PageFooter.propTypes = {
    background: PropTypes.string
};

PageFooter.defaultProps = {
    background: null
};

export default PageFooter;
