import React from "react";
import PropTypes from "prop-types";

import classnames from "classnames";

import { Link } from "gatsby";

import { IoIosArrowBack } from "react-icons/io";

import dirPath from "../../util/templateDirPath";
import { privatePathPrefix } from "../../constants/privatePathPrefix";

const Icon = ({ icon: Component, lightDark, classes, color }) => (
    <Component
        className={`w-5 h-5 inline align-bottom ${classes}`}
        color={color ?? (lightDark ? "white" : "black")}
    />
);

Icon.propTypes = {
    classes: PropTypes.string,
    color: PropTypes.string,
    icon: PropTypes.node.isRequired,
    lightDark: PropTypes.string
};

Icon.defaultProps = {
    classes: "",
    color: "",
    lightDark: ""
};

const DirButton = ({ linkTo, children, classes, disabled }) => (
    <div
        className={classnames("flex-initial text-center", classes.outerDiv)}
        style={disabled ? { pointerEvents: "none", cursor: "default" } : {}}
    >
        <Link to={`${privatePathPrefix}${linkTo}`}>
            <button
                className={classnames(
                    "btn bg-gray-200 hover:bg-gray-300 text-gray-700 font-bold py-3 px-4 rounded",
                    classes.button
                )}
                type="button"
                disabled={disabled}
            >
                {children}
            </button>
        </Link>
    </div>
);

DirButton.propTypes = {
    linkTo: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool.isRequired,
    classes: PropTypes.object
};

DirButton.defaultProps = {
    classes: {}
};

const SubDirBar = ({ subDirs, fallback, insideDir }) => {
    const { key: fallbackKey, displayName: fallbackName } = fallback;

    return (
        <div
            className={classnames("flex py-3 pb-5", {
                "justify-center": !insideDir,
                "justify-between": insideDir
            })}
        >
            {insideDir && (
                <DirButton linkTo={dirPath(fallbackKey)} classes={{ outerDiv: "px-4 top-0" }}>
                    <Icon icon={IoIosArrowBack} /> <span>{`Back to ${fallbackName}`}</span>
                </DirButton>
            )}
            <div className={classnames("flex-grow flex justify-center")}>
                {subDirs.map(({ key: subDirKey, hasData, displayName }) => {
                    const isCurrentDir = insideDir === subDirKey;
                    return (
                        <DirButton
                            linkTo={dirPath(fallbackKey, subDirKey)}
                            disabled={!hasData || isCurrentDir}
                            style={isCurrentDir ? { pointerEvents: "none", cursor: "default" } : {}}
                            classes={{
                                outerDiv: "mx-2",
                                button: classnames({
                                    "shadow-inner bg-blue-100 text-blue-400": isCurrentDir,
                                    "opacity-50": !hasData
                                })
                            }}
                        >
                            {displayName}
                        </DirButton>
                    );
                })}
            </div>
            {insideDir && (
                <DirButton
                    linkTo={`/${fallbackKey}/templates`}
                    classes={{ outerDiv: "px-4 opacity-0" }}
                    disabled={true}
                >
                    <Icon icon={IoIosArrowBack} /> <span>{`Back to ${fallbackName}`}</span>
                </DirButton>
            )}
        </div>
    );
};

SubDirBar.propTypes = {
    subDirs: PropTypes.array.isRequired,
    fallback: PropTypes.object.isRequired,
    insideDir: PropTypes.bool.isRequired
};

export default SubDirBar;
