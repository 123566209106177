import React from "react";
import PropTypes from "prop-types";

const SubHeading = ({ children }) => {
    return (
        <h2 className="text-center text-xl text-gray-500 mt-7 mb-5 font-light pb-2 border-b-2 border-gray-200">
            {children}
        </h2>
    );
};

SubHeading.propTypes = {
    children: PropTypes.node.isRequired
};

export default SubHeading;
