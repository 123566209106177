import React, { Fragment } from "react";
import PropTypes from "prop-types";

import sumBy from "lodash/sumBy";
import values from "lodash/values";
import size from "lodash/size";

export const CHECKBOX = {
    DEV_ONLY: "devOnlyCheckbox",
    UPCOMING: "upcomingCheckbox",
    DEPRECATED: "deprecatedCheckbox",
    DEFAULT: "defaultCheckbox"
};

const checkboxes = [
    {
        key: CHECKBOX.UPCOMING,
        label: "Show Upcoming"
    },
    {
        key: CHECKBOX.DEV_ONLY,
        label: "Show Dev-Only"
    },
    {
        key: CHECKBOX.DEPRECATED,
        label: "Show Deprecated"
    },
    {
        key: CHECKBOX.DEFAULT,
        label: "Show Default"
    }
];

export const defaultFilters = {
    ...checkboxes.reduce((boxes, box) => ({ ...boxes, [box.key]: false }), {}),
    [CHECKBOX.DEFAULT]: true
};

const FilterBar = ({ filterSettings, setFilterSettings, filteredEmails, groupedEmails }) => {
    const handleChange = (event) => {
        setFilterSettings({ ...filterSettings, [event.target.name]: event.target.checked });
    };

    return (
        <div className="flex justify-center top-0 sticky z-50">
            <div className="inline-flex justify-center flex-wrap bg-gray-100 rounded-b-lg px-12 py-2 mb-3 border-l-4 border-r-4 border-b-4 border-gray-300">
                {checkboxes.map((cbx) => (
                    <Fragment key={cbx.key}>
                        <div className="p-2 px-3">
                            <label key={cbx.key}>
                                {cbx.label}&nbsp;&nbsp;
                                <input
                                    name={cbx.key}
                                    type="checkbox"
                                    checked={filterSettings[cbx.key]}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>
                    </Fragment>
                ))}
                <div className="font-semibold p-2 ml-3">
                    -&nbsp;&nbsp;Showing{" "}
                    <span className="font-bold text-green-500">
                        {sumBy(values(filteredEmails), size)}
                    </span>{" "}
                    of{" "}
                    <span className="font-bold text-red-500">
                        {sumBy(values(groupedEmails), size)}
                    </span>{" "}
                    Emails
                </div>
            </div>
        </div>
    );
};

FilterBar.propTypes = {
    /**
     * Object used to store the current filter settings
     */
    filterSettings: PropTypes.object.isRequired,
    /**
     * Function used to set the current filter settings
     */
    setFilterSettings: PropTypes.func.isRequired,
    /**
     * filteredEmails: Used to count the number of emails currently being shown
     */
    filteredEmails: PropTypes.object.isRequired,
    /**
     * groupedEmails: Used to count the total number of emails
     */
    groupedEmails: PropTypes.object.isRequired
};

export default FilterBar;
