import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";

const RawHeading = ({ children, className, brandInfo }) => {
    const imagesPath = process.env.GATSBY_MJML_BASE_URL;
    const { key: brandKey, logoType = "png" } = brandInfo;
    const logoPath = `${imagesPath}/${brandKey}/images/logo.${logoType}`;

    return (
        <div className={`bg-gray-700 py-3 ${className}`}>
            <img src={logoPath} className="block mx-auto mt-6 my-2 max-w-sm" alt="Brand logo" />
            <h1 className="text-center text-2xl text-gray-100 mt-5 mb-3  font-light">{children}</h1>
        </div>
    );
};

const PageHeading = styled(RawHeading)`
    ${({ brandInfo: { palette } }) =>
        palette.primary ? `background-color: ${palette.primary};` : ""}
`;

PageHeading.propTypes = {
    children: PropTypes.node.isRequired,
    brandInfo: PropTypes.object
};

PageHeading.defaultProps = {
    brandInfo: null
};

export default PageHeading;
