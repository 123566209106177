import React from "react";
import PropTypes from "prop-types";

import BreadCrumbs from "../BreadCrumbs";
import PageFooter from "../PageFooter";
import ScopedTheme from "../ScopedTheme";

const Layout = ({ children, crumbs, brandColor }) => {
    return (
        <ScopedTheme>
            <BreadCrumbs crumbs={crumbs} />

            {children}

            <PageFooter background={brandColor} />
        </ScopedTheme>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    brandColor: PropTypes.string.isRequired,
    crumbs: PropTypes.arrayOf(
        PropTypes.shape({
            to: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired
        })
    )
};

Layout.defaultProps = {
    crumbs: []
};

export default Layout;
