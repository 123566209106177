import React from "react";
import { AiOutlinePicture } from "react-icons/ai";
import { Link } from "gatsby";

import classnames from "classnames";
import { privatePathPrefix } from "../../constants/privatePathPrefix";

const EmailItem = ({
    email: {
        name,
        frontmatter,
        fields: { slug }
    }
}) => {
    const {
        description,
        order,
        orderSuffix,
        tags,
        title,
        deployedBy,
        deployedOn,
        devOnly,
        upcoming,
        deprecated
    } = frontmatter;
    return (
        <li
            className={`list-disc ml-2 mt-0.5 mb-0.5  ${classnames({
                "text-pink-500": devOnly,
                "text-gray-500": deprecated,
                "text-gray-600": !devOnly && !deprecated
            })}`}
        >
            {order}
            {orderSuffix} -
            <Link to={`${privatePathPrefix}/${slug}`} className="underline">
                <div className="inline-flex  items-center">
                    <span className="p-1">
                        {title || name} {description ? <>( {description})</> : null}{" "}
                    </span>
                </div>
            </Link>
            &nbsp;
            <Link to={`${privatePathPrefix}/${slug}rendered`}>
                <AiOutlinePicture className="w-6 h-6 inline align-bottom" />
            </Link>
            <div className="inline-flex items-center w-full">
                {deployedOn && (
                    <span className="rounded-full uppercase bg-yellow-200 text-yellow-600 py-0.5 px-2 mx-1 text-sm ">
                        {deployedOn}
                    </span>
                )}
                {deployedBy && (
                    <span className="rounded-full uppercase bg-indigo-200 text-indigo-600 py-0.5 px-2 mx-1 text-sm">
                        {deployedBy}
                    </span>
                )}
                {upcoming && (
                    <span className="rounded-full uppercase bg-green-200 text-green-600 py-0.5 px-2 mx-1 text-sm">
                        UPCOMING
                    </span>
                )}
                {devOnly && (
                    <span className="rounded-full uppercase bg-pink-200 text-pink-600 py-0.5 px-2 mx-1 text-sm">
                        NON-PRODUCTION
                    </span>
                )}
                {deprecated && (
                    <span className="rounded-full uppercase bg-gray-200 text-gray-500 py-0.5 px-2 mx-1 text-sm">
                        DEPRECATED
                    </span>
                )}
                {/* {tags && */}
                {/*  tags.length && */}
                {/*  tags.map((tag) => ( */}
                {/*    <span className="rounded-full uppercase bg-gray-200 text-grey-600 py-0.5 px-2 mx-1 text-sm"> */}
                {/*      {tag} */}
                {/*    </span> */}
                {/*  ))} */}
            </div>
        </li>
    );
};

EmailItem.propTypes = {};

export default EmailItem;
